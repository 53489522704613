.navigation-bar {
  justify-content: space-between;
  box-shadow: none;
  border-radius: 0;
  margin: 0;
  padding-left: 0;
  padding-right: 0;
  min-height: 45px;
  position: relative;
  background-color: #438eb9;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  padding: 0.5rem 0rem 0.5rem 1rem;
  position: fixed;
  width: 100%;
  overflow: hidden;
  z-index: 99;
}

.navigation-bar a {
  margin-left: 10px;
  color: white;
  text-decoration: none;
  font-family: "Raleway", sans-serif;
}

.logout-button {
  background: none;
  color: white;
  cursor: pointer;
}
.logout-button:hover {
  cursor: pointer;
  text-decoration: underline;
}

.user-info {
  color: #fff;
  text-align: end;
  border-right: 1px solid;
}
.user-info span {
  margin-right: 15px;
}

.nav-contents {
  display: flex;
}
