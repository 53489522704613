.login-main-container {
	text-align: center;
	align-items: center !important;
}
.widget-header {
	background: #438eb9;
	color: #fff;
	min-height: 31px;
	padding-left: 10px;
}
.login-form {
	width: 45%;
	border: 1px solid #d5d5d5;
	margin: auto;
}
.h6 {
	text-align: center;
	font-weight: bold;
}
.login-form .form-content {
	padding: 12px;
	text-align: center;
	margin: 22px 0px;
}
.login-form .form-group label {
	text-align: end;
}
.form-content input {
	border: 1px solid #d5d5d5;
	padding: 7px 4px;
	line-height: 1.2;
	font-size: 14px;
	font-family: inherit;
	background-color: rgb(232, 240, 254);
}
.login-submit {
	width: 8rem;
	border: none;
	padding: 5px;
	border-radius: 5px;
	background: #87b87f;
	color: #fff;
	font-weight: bold;
}
.login__header nav {
	position: relative !important;
}
.login-main-container .form-group {
	display: flex;
}

.login-main-container .ant-spin .ant-spin-dot-item {
	background-color: white;
}
.login-content img {
	height: 38px;
	margin-bottom: 25px;
}
