.sidebar {
	height: 100%;
	border: 1px solid #ccc;
	border-width: 0 1px 0 0;
	background-color: #f2f2f2;
	position: fixed;
	z-index: 2;
	overflow-x: hidden;
	margin-top: 64px;
	transition: left 0.3s ease;
	width: 17%;
}

.sidebar.open {
	left: 0;
}

.toggle-button {
	position: absolute;
	top: 10px;
	left: 10px;
}

.sidebar-content li {
	border: 1px solid #ccc;
	cursor: pointer;
}
.sidebar-content li a {
	display: flex;
	align-items: center;
}

.sidebar-content li a:hover {
	border-left: 5px solid #438eb9;
}

.sidebar-content li a.active {
	color: #438eb9 !important;
	border-left: 5px solid #438eb9 !important;
}

.sidebar-content a {
	text-decoration: none;
	display: block;
	padding: 10px 5px;
	color: #616161;
	font-size: 12px;
}

.svg-inline--fa {
	margin-right: 12px;
}

h3 {
	margin-bottom: 10px;
}

ul {
	list-style: none;
	padding: 0;
	margin: 0;
}
