.main-container {
  display: flex;
}

.App-container {
  margin-left: 0%;
  margin-right: 0;
  margin-top: 0;
  min-height: 100%;
  font-size: 87%;
}
.btn-export {
  display: inline-block;
  color: white;
  background-color: #027148;
  font-weight: 400;
  text-align: center;
  user-select: none;
  border: 1px solid transparent;
  padding: 0.39rem 0.75rem;
  font-size: 1rem;
  line-height: 1.5;
  border-radius: 0.25rem;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
.btn-export:hover {
  background-color: #0a9b66;
  border-color: #026b45;
  border: 1px solid transparent;
  cursor: pointer;
}
.btn-Add {
  display: inline-block;
  color: white;
  background-color: #87b87f;
  font-weight: 400;
  text-align: center;
  user-select: none;
  border: 1px solid transparent;
  padding: 0.39rem 0.75rem;
  font-size: 1rem;
  line-height: 1.5;
  border-radius: 0.25rem;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
.btn-Add:hover {
  background-color: rgb(127, 206, 115);
  border-color: #87b87f;
  border: 1px solid transparent;
  cursor: pointer;
}
.btn-primary {
  background-color: #5eade2;
  border-color: #6fb3e0;
}
.btn-primary:hover {
  background-color: #1b6aaa;
  border-color: #428bca;
}
