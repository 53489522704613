.App-container {
	width: 82%;
	margin-top: 50px;
}

.sidebar-main {
	width: 17%;
}

.page-header h1 {
	color: #438eb9;
	padding: 0;
	margin: 10px 8px;
	font-size: x-large;
	font-weight: 400;
}

.page-header {
	margin: 0 0 12px;
	border-bottom: 1px dotted #e2e2e2;

	padding-top: 7px;
}

.page-content {
	margin-top: 10px;
	margin-right: 1%;
	margin-left: 1%;
}
.page-content btn {
	background-color: #5eade2;
	border-color: #6fb3e0;
}

.page-content btn:hover {
	background-color: #1b6aaa;
	border-color: #428bca;
}

/* table-Striped odd even -------------------------------*/

table tbody tr:nth-of-type(odd) {
	background-color: #817b7b0d;
}

/* customer satisfaction -------------------------------*/
.form-control {
	border-radius: 0%;
}
.form-group {
	display: grid;
}

.input-group-addon {
	text-align: center;
	background-color: aliceblue;
	width: 10%;
	margin-top: 0px;
	border: 1px solid #ced4da;
	height: 37px !important;
}
/* datepicker icon----------------------------------- */
.input-group-addon label {
	margin-top: 4px;
}

.react-datepicker-wrapper {
	width: 90%;
}
.input-group span i {
	margin-top: 5px;
}

/* Table Css ------------------------------------------ */

.table_heading {
	background-color: #438eb9;
	color: white;
	margin-top: 10px;
}
table {
	margin-top: 15px;
}
.table thead th {
	vertical-align: 0%;
	border: 1px solid white;
}
.ant-table-container {
	margin-top: 5px;
}

.Action-button {
	display: flex;
	justify-content: center;
	border-radius: 0;
	font-size: larger;
}
.action-button {
	display: flex;
	justify-content: space-between;
}
.left-action-button {
	display: flex;
}

.right-action-button {
	position: absolute;
	display: flex;
	right: 24px;
	align-items: center;
}
.right-action-button .countNumber {
	margin: 0px 20px 0px 0px;
	padding: 5px;
	background-color: #817b7b0d;
}
.right-action-button .countNumber label {
	margin-bottom: 0px;
	font-weight: 500;
}

.Action-button button {
	background-color: #5eade2;
	border: 1px solid #5eade2;
}
.ant-table-thead {
	background-color: #438eb9;
	color: white;
	margin-top: 10px;
}

.ant-table-thead tr th {
	color: white;
}
.ant-table-wrapper .ant-table-thead > tr > th {
	background: none;
	color: none;
}
.ant-table-cell {
	width: 128px !important;
}

.ant-table-wrapper .ant-table-thead > tr > th {
	color: #fff;
}

.text-nowrap .ant-table-wrapper .ant-table-thead > tr > th {
	white-space: nowrap;
}
.css-13cymwt-control {
	border-radius: 0px !important;
}

/* modal .css --------------------------------------------*/

.modal-title {
	color: white;
}
.modal-header {
	background-color: #438eb9;
}
.modal-body {
	display: flex;
	white-space: nowrap;
	/* overflow: hidden; */
	text-overflow: ellipsis;
}
.picker-panel {
	position: relative;
}
.year-picker {
	line-height: 2.5;
	width: 229px;
	border-radius: 0px;
}

/* Autocomplete suggestions list styles */
.suggestions-list {
	list-style: none;

	margin-top: 70px;
	border-top: none;
	border: 1px solid black;

	background-color: #fff;
	position: absolute;
	width: 88%;
	z-index: 2;
	margin-left: 15px;
}

.suggestions-list li {
	padding: 3px 7px;
	padding: 5px 10p;
	cursor: pointer;
}

.suggestions-list li:hover {
	background-color: #046ad8;
	color: white;
}
.selectfield {
	width: 100%;
	border-radius: 0px;
	border: 1px solid #ced4da;
	height: 35px;
	color: #495057;
}

.validation-error {
	color: red;
	font-size: 12px;
	margin-top: 5px;
}

.validation-error::before {
	content: "* ";
	color: red;
}
.form-control:disabled {
	background-color: none;
}
.form-group textarea {
	height: 37px !important;
}
.required::after {
	content: "*";
	color: red;
	font-size: 20px;
}

.alert {
	margin-right: 40% !important;
}

.react-datepicker__navigation-icon {
	top: 5px;
}
.invalid {
	border: 1.5px solid red !important;
}

.custom-xl-modal {
	max-width: 80%;
	width: 90%;
	max-height: 90vh;
}

.nav-link {
	display: block;
	padding: 0.5rem 13px;
}

.modal-lg-ALM {
	max-width: 80%;
}
.modal-body-ALM .status-ALM {
	margin-top: -19px;
}
.table_main,
.table_container {
	height: 100%;
}

.table td,
.table th {
	padding: 0.75rem;
	vertical-align: middle !important;
	border-top: 1px solid #dee2e6;
}
.modal-lg-projectProcess {
	max-width: 90%;
}
.modal-lg-projectProcess .table td {
	padding: 0.5rem 0.1rem;
}
.modal-fade-ALM {
	padding-right: 0% !important;
}

.table thead th {
	text-align: center;
	background-color: #438eb9;
	color: white;
}

.PPC-master-update .true {
	width: 90% !important;
}
.w-90 {
	width: 90%;
}
.design {
	max-width: 100%;
	min-width: 165px !important;

	padding: 0px 10px !important;
}

.view-ALM .design {
	max-width: 100%;
	width: 154px !important;

	height: 40px !important;
	padding: 0px 5px !important;
}
.view-ALM .view-modal {
	max-width: 90% !important;
}
.ALM-top-design {
	flex: 0 0 25% !important;
	max-width: 25% !important;
}
.ant-tabs-nav-wrap {
	display: flex;
	font-weight: bold;
	justify-content: space-around;
}

.modal-overlay {
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background-color: rgba(0, 0, 0, 0.5);
	z-index: 1040;
	overflow-y: auto;
}

/* ==================================== */
.comments-cell {
	max-height: 50px;
	overflow: hidden;
	position: relative;
}

.comments-content {
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
}

.show-more {
	position: absolute;
	bottom: 0;
	right: 0;
	background-color: #fff;
	cursor: pointer;
	padding: 4px;
	font-size: 12px;
	color: #1890ff;
}
.App-container .ant-table-wrapper table {
	text-align: center !important;
}
.ant-table-wrapper .ant-table-thead > tr > th {
	text-align: center !important;
}

.view-table table th {
	text-align: center !important;
}
.view-top-content {
	display: flex;
	justify-content: space-around;
}

.editdate {
	display: contents;
}

.view-ALM .ant-table-cell th {
	display: flex;
	justify-content: center;
}
.ant-table-wrapper .ant-table-thead > tr > th > td {
	text-align: center;
}
.status-ALM {
	margin: 0;
	padding: 0;
}
.ant-table-wrapper .ant-table-cell,
.ant-table-wrapper .ant-table-thead > tr > th {
	padding: 13px 12px !important;
}
.ALM-Module .ALM-Table .update_ALM .ant-table-wrapper .ant-table-cell {
	padding: 13px 4px !important;
}
.PPC-module .PPC-Table .update_PPC .ant-table-wrapper .ant-table-cell {
	padding: 13px 4px !important;
}
.update_PPC .ant-table-wrapper .ant-table-thead > tr > th {
	word-wrap: break-word;
	white-space: initial;
}
.ant-table-tbody .ant-table-cell {
	border: 1px solid #dee2e6 !important;
}

::-webkit-scrollbar {
	width: 2px;
	/* margin-left: 2px; */
	height: 2px;
}

::-webkit-scrollbar-track {
	background: #f1f1f1;
	margin-right: 5px;
}

::-webkit-scrollbar-thumb {
	background: #918b8b;
}

.application__life table thead,
.application__life table tbody {
	width: 100% !important;
}

.tab__section {
	padding: 0 2rem;
}

.tab__section .ant-tabs-nav-list {
	width: 100vw;
	justify-content: space-around;
}

.tab__section .ant-tabs .ant-tabs-tab + .ant-tabs-tab {
	margin: 0 0;
}

.text-wrap .ant-table-tbody .ant-table-cell {
	text-wrap: wrap;
}

.view-modal-projectProcess {
	max-width: 90% !important;
}

.bug-table {
	border: 2px solid #6c757d;
	position: absolute;
	right: 20px;
	top: 122px;
	text-align: center;
	width: 16%;
}
.bug-table table thead {
	background-color: #438eb9;
	color: white;
}
.bug-table table {
	margin-top: 0px;
	width: 100%;
}
.right-action-button .btn-export .ant-spin-spinning {
	margin: 0px 23px !important;
}
.right-action-button .ant-spin .ant-spin-dot-item,
.left-action-button .ant-spin .ant-spin-dot-item {
	background-color: white;
}
.left-action-button .loading .ant-spin-spinning {
	margin: 0px 15px !important;
}

.PPC-master-update .date__picker {
	flex: 0 0 50%;
	max-width: 50%;
}

/* test */
