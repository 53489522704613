.ant-modal .ant-modal-header {
  background-color: #438eb9 !important;
  padding: 12px !important;
}

.ant-modal .ant-modal-title {
  color: white !important;
}

.ant-modal .ant-modal-content {
  padding: 0px;
}

.ant-modal .ant-modal-body {
  padding: 20px !important;
  font-size: 18px;
  display: flex;
  border-bottom: 1px solid #bfbfbf;
}

.ant-modal .ant-modal-footer {
  padding: 0px 20px 20px 20px !important;
}

.ant-modal .ant-modal-footer .ant-btn-primary {
  background-color: #438eb9;
}
.ant-modal .ant-modal-footer .ant-btn-primary:hover {
  background-color: #1b6aaa;
}
